<template>
  <div id="app">
  <!-- Anmeldebutton nur anzeigen, wenn nicht angemeldet -->
    <button v-if="!isLoggedIn" @click="signInWithGoogle">Anmelden</button>

    <!-- Abmeldebutton und Profilinformationen anzeigen, wenn angemeldet -->
    <div v-if="isLoggedIn">
      <button @click="logout">Abmelden</button>
      <img :src="profileImageUrl" alt="Profilbild">
      <span>{{ profileName }}</span>
   
  </div>
  <!-- Toggle-Buttons für jeden Kalender -->
  <div class="calendar-toggles">
    <div v-for="calendar in calendars" :key="calendar.id" class="calendar-toggle">
  <button 
    :style="{ backgroundColor: calendar.color }" 
    :class="[{ 'light-background': isLight(calendar.color), 'dark-background': !isLight(calendar.color) }, { active: calendar.isActive }]"
    @click="toggleCalendar(calendar.id)">
    {{ calendar.name }}
  </button>
</div>

  </div>
   <Calendar :events="events" @yearChanged="handleYearChange"/>
  </div>
</template>

<script>
import Calendar from './components/CalendarView.vue';
import axios from 'axios';



export default {

  name: 'App',
  data() {
    return {
    events: [],
	calendars: [],
     isLoggedIn: false,
      profileImageUrl: '',
      profileName: '',
       selectedCalendars: [], // IDs der ausgewählten Kalender
      year: new Date().getFullYear(), // Aktuelles Jahr als Stand
    };
  },
  created() {
    this.fetchUserData();
    this.fetchCalendarData();
  },
 mounted() {
 
    this.adjustScale();
    window.addEventListener('resize', this.adjustScale);
  },

  beforeUnmount() { // oder 'beforeDestroy' in Vue 2
    window.removeEventListener('resize', this.adjustScale);
  
  },
    components: {
    Calendar
},
  methods: {
    adjustScale() {
      const scaleFactor = window.innerWidth / document.documentElement.clientWidth;
      document.documentElement.style.transform = `scale(${scaleFactor})`;
    },
  
  handleYearChange(newYear) {
     this.year = newYear; // Aktualisieren des Jahres
      this.fetchCalendarEvents(); // Aktualisieren der Events für das neue Jahr
      },
  fetchCalendarEvents() {
  console.log(this.calendars);
      // Erstellen der URL mit Parametern
      const params = {
        calendarIds: this.selectedCalendars.join(','), // IDs als kommaseparierte Zeichenkette
        year: this.year
      };

      axios.get('https://jahreskalender.app/api.php', { params })
        .then(response => {
          // Verarbeiten der Antwort
         //  this.events = response.data.events;
       this.events = response.data.events.map(event => {
       console.log('Eventdaten:', response.data.events);
       // const calendar = this.calendars.find(cal => cal.id === event.calendarId);
      //  console.log('Event-Kalender-Zuordnung:', event.summary, calendar);
       // const color = calendar ? calendar.color : '#000000'; // Setzt eine Standardfarbe, falls kein Kalender gefunden wird


  const isAllDay = this.isAllDayEvent(event);
        // Logik zur Bestimmung, ob das Event mehrtägig ist
        const isMultiDay = this.isMultiDayEvent(event);



        return { 
          ...event, isAllDay, isMultiDay 
        };
      });
     // console.log(this.events);
          console.log(response.data);
        })
        .catch(error => {
          console.error('Fehler beim Abrufen der Kalenderdaten', error);
        });
    },
    
  isAllDayEvent(event) {
  // Beispiel: Ein ganztägiges Event könnte den ganzen Tag in der Start- und Endzeit haben
  return event.startTime === '00:00' && event.endTime === '23:59';
},

 isMultiDayEvent(event) {
  const startDate = new Date(event.start);
  const endDate = new Date(event.end);

  // Subtrahieren Sie einen Tag vom Enddatum und vergleichen Sie es mit dem Startdatum
  endDate.setDate(endDate.getDate() - 1);
  return startDate.getTime() < endDate.getTime();
},
  isLight(color) {
    const hex = color.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 155;
  },
  // eslint-disable-next-line no-unused-vars
  toggleCalendar(calendarId) {
   console.error('Kalender toggle');
    this.calendars = this.calendars.map(calendar => {
        if (calendar.id === calendarId) {
            // Umschalten des aktiven Status
            const isActive = !calendar.isActive;

            // Aktualisieren der selectedCalendars Liste
            if (isActive) {
                this.selectedCalendars.push(calendarId);
            } else {
                this.selectedCalendars = this.selectedCalendars.filter(id => id !== calendarId);
            }

            return { ...calendar, isActive: isActive };
        }
        return calendar;
    });

    // Rufen Sie fetchCalendarEvents auf, um die Daten für die ausgewählten Kalender zu holen
    this.fetchCalendarEvents();
},
   fetchCalendarData() {
      axios.get('https://jahreskalender.app/api.php')
        .then(response => {
        console.log('Kalenderdaten:', response.data);
         this.calendars = response.data.calendars.map(calendar => ({
          ...calendar,
          isLight: this.isLight(calendar.color),
          isActive: false // Standardmäßig alle Kalender aktivieren
        }));
      })
        .catch(error => {
          console.error('Fehler beim Abrufen der Kalenderdaten', error);
        });
        // Anwenden der Funktion auf Ihre Kalenderdaten

        
    },
  fetchUserData() {
      axios.get('https://jahreskalender.app/api.php')
        .then(response => {
          // Vorausgesetzt, die Antwort enthält Name und Bild URL
        this.profileName = response.data.fullName;
		//this.calendars = response.data.calendars;
          this.profileImageUrl = response.data.profileImageURL;
          this.isLoggedIn = true;
        })
        .catch(error => {
          console.error('Fehler beim Abrufen der Benutzerdaten', error);
          this.isLoggedIn = false;
      this.profileImageUrl = '';
      this.profileName = '';
        });
    },
	logout() {
	console.log('Abmeldung gestartet');
      // Logik zur Abmeldung
      // Bei Erfolg:
      this.isLoggedIn = false;
      this.profileImageUrl = '';
      this.profileName = '';
  axios.post('https://jahreskalender.app/logout.php')
      .then(() => { // 'response' entfernt, da es nicht verwendet wird
        console.log('Abmeldung erfolgreich');
        // Weitere Aktionen nach erfolgreicher Abmeldung
      })
      .catch(error => {
        console.error('Fehler bei der Abmeldung', error);
      });

      // Optional: Umleiten auf die Anmeldeseite oder Startseite
       //window.location.href = '/';
    },
  
  signInWithGoogle() {
    // Leitet den Benutzer zur PHP-Anmeldeseite um
    window.location.href = 'https://jahreskalender.app/api.php';
  },
  getGoogleCalendarData() {
      axios.get('https://jahreskalender.app/api.php')
        .then(response => {
          // Verarbeiten Sie hier das Ergebnis
          console.log(response.data);
        })
        .catch(error => {
          console.error('Es gab einen Fehler bei der Anfrage:', error);
        });
    },
  
  
    
    onFailure(error) {
      console.log(error);
    }
  }
};
</script>

 

<style>
/* Global styles */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 /* text-align: center;*/
  color: #2c3e50;
  margin-top: 60px;
}

.calendar-toggles {
  display: flex;
  flex-wrap: wrap;
}
.calendar-toggle {
  margin-right: 10px; /* Fügt einen kleinen Abstand zwischen den Buttons hinzu */
  margin-bottom: 10px; 
}
.calendar-toggle button {
  background-color: #FFFFFF; /* Standard-Hintergrundfarbe */
 
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: box-shadow 0.3s;
}

.calendar-toggle button{
opacity:0.5;
}

.calendar-toggle button.active {
  /* Stile für aktive Kalender */
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
  opacity:1;
}

/* Dynamische Textfarbe basierend auf Hintergrundfarbe */
.calendar-toggle button[data-light-background="true"] {
  color: #000000; /* Dunkle Schrift auf hellem Hintergrund */
}

.calendar-toggle button[data-light-background="false"] {
  color: #FFFFFF; /* Helle Schrift auf dunklem Hintergrund */
}

.light-background {
  color: #000000; /* Dunkle Schrift auf hellem Hintergrund */
}

.dark-background {
  color: #FFFFFF; /* Helle Schrift auf dunklem Hintergrund */
}



</style>