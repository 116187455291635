<template>
<button @click="generatePDF">Export to PDF</button>

<div class="overlay" :class="{ 'overlay-visible': popupVisible }"></div>

<div ref="popupRef" class="popup" :class="{ 'popup-visible': popupVisible }">
   <div v-for="event in formattedEvents" :key="event.id" class="event-item">
    
      {{ event.summary }} <!-- Verwenden Sie `summary` oder eine andere relevante Eigenschaft -->
    </div>
    
    <button @click="closePopup">Schließen</button>
</div>

  <div class="calendar-container"> <div class="year-display">{{ year }}</div>
  <div class="calendar-controls">
    <button @click="changeYear(-1)">Vorheriges Jahr</button>
    <button @click="changeYear(1)">Nächstes Jahr</button>
  </div>

<div class="calendar-year" ref="calendarElement">
    <!-- Schleife durch alle Monate des Jahres -->
    <div class="calendar-month" v-for="month in processedCalendarData" :key="month.id">
    <div class="days-grid">
      <!-- Leere Zellen und Zelle mit dem Monatsnamen -->
      <div 
        class="day" 
        :class="{ 
          'month-name-cell': emptyDay.isMonthName,
          'empty-cell': emptyDay.isEmpty && !emptyDay.isMonthName
        }" 
        v-for="emptyDay in month.leadingEmptyDays" 
        :key="`empty-${month.id}-${emptyDay}`"
      >
        <span v-if="emptyDay.isMonthName">{{ emptyDay.monthName }}</span>
      </div>
        
        <!-- Tatsächliche Tage des Monats -->
        <div class="day" v-for="day in month.days" :key="day.date"
  :class="{ weekend: day.isWeekend, today: day.isToday, 'day-with-event': hasMultiDayOrAllDayEvent(day)}"
  :style="getDayBorderStyle(day)"
  @click="day.events.length > 2 ? showPopup(day) : null">
   <div class="day-date">
    {{ day.dayString }}
  </div> <!-- Statt day.dayOfMonth und day.dayOfWeek -->
  
  <div v-if="day.events.length" class="events">
  <ul class="event-list">
    <li v-for="event in day.firstTwoEvents" :key="event.id">
      {{ truncateEventTitle(event.summary) }}<!-- Event-Details hier -->
    </li>
  </ul>
  <div v-if="day.hasMoreEvents" class="more-events-indicator" @click="showPopup(day)">
    +{{ day.events.length - day.firstTwoEvents.length }}
  </div>
</div>

 </div>

      </div>
    </div>
  </div>
  </div>
   

</template>



<script>
import { ref, watch, computed, onMounted } from 'vue';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

//import Month from './CalendarMonth.vue';
//import Day from './CalendarDay.vue';

export default {
props: {
    events: {
    type:Array,
    default: () => []}
  },
  
  
  components: {
  //  Month
 // Day
  },
  setup(props, { emit }) {
  
  const calendarElement = ref(null);
  
  const getDayBorderStyle = (day) => {
  const multiDayOrAllDayEvent = day.events.find(event => event.isMultiDay || event.isAllDay);
  if (multiDayOrAllDayEvent) {
    const borderColor = multiDayOrAllDayEvent.color;
    return {
      borderTop: '1px solid grey',
      borderRight: `5px solid ${borderColor}`,
      borderBottom: '1px solid grey',
      borderLeft: '1px solid grey'
    };
  } else {
    return {
      border: '1px solid grey'
    };
  }
};

  
  console.log('Events prop:', props.events);
  const year = ref(new Date().getFullYear());
    const calendarData = ref([]);
   // const showPopupFlag = ref(true);
    const additionalEvents = ref([]);
    const popupVisible = ref(false);
    const hasMultiDayOrAllDayEvent = (day) => {
      return day.events.some(event => event.isMultiDay || event.isAllDay);
    };
    
 const generatePDF = async () => {
  if (!calendarElement.value) {
    console.error('Kalenderelement ist nicht vorhanden.');
    return;
  }

  // Berechnen der Größe des PDFs und des Canvas
  const mmPerInch = 25.4;
  const a1Width = 841; // in mm (Querformat)
  const a1Height = 594; // in mm (Querformat)
  const dpi = 150;
  const margin = 50; // Rand von 5cm

  // Skalierung für hohe Auflösung
  const scaleFactor = dpi / mmPerInch;

  // Generieren des Canvas mit der entsprechenden Größe
  const canvas = await html2canvas(calendarElement.value, {
    scale: scaleFactor,
    logging: true,
    useCORS: true
  });

  // Erstellen des PDFs im Querformat
  const pdf = new jsPDF({
    orientation: 'landscape', // Querformat
    unit: 'mm',
    format: 'a1'
  });

  // Berechnen der Größe des Bildes nach Abzug des Randes
  const imgWidth = a1Width - 2 * margin;
  const imgHeight = a1Height - 2 * margin;

  // Hinzufügen des Bildes zum PDF
  const imageData = canvas.toDataURL('image/png');
  pdf.addImage(imageData, 'PNG', margin, margin, imgWidth, imgHeight);

  // Speichern des PDFs
  pdf.save('calendar-a1-landscape.pdf');
};



const truncateEventTitle = (title) => {
    if (title.length > 19) {
      return title.substring(0, 19) + '...';
    }
    return title;
  };
  
    const getDayBorderColor = (day) => {
      const multiDayOrAllDayEvent = day.events.find(event => event.isMultiDay || event.isAllDay);
      return multiDayOrAllDayEvent ? { 'border-color': multiDayOrAllDayEvent.color } : {};
    };

const popupRef = ref(null);

    const showPopup = (day) => {
     additionalEvents.value = day.events;
     popupVisible.value = true;
      console.log('Zugewiesene Events:', additionalEvents.value);
      if (popupRef.value) {
        popupRef.value.style.visibility = 'visible';
      }
    };
    const formattedEvents = computed(() => {
  return additionalEvents.value.map(event => ({
    ...event
  }));
});

    const closePopup = () => {
      if (popupRef.value) {
      popupVisible.value = false;
        popupRef.value.style.visibility = 'hidden';
      }
    };

    onMounted(() => {
      closePopup(); // Initialisiert das Popup als ausgeblendet
    });
    
const processedCalendarData = computed(() => {
console.log("huhu");
      return calendarData.value.map(month => ({
        ...month,
        days: month.days.map(day => ({
          ...day,
          firstTwoEvents: day.events.slice(0, 2),
          hasMoreEvents: day.events.length > 2
        }))
      }));
//console.log("huhu hier geht es auch noch");   

   });
watch(processedCalendarData, (newValue) => {
      console.log('processedCalendarData hat sich geändert:', newValue);
    });
   
watch(() => props.events, (newEvents) => {
 console.log('Events haben sich geändert:', newEvents);
  const safeEvents = newEvents || []; // Fallback auf leeres Array, falls null
  calendarData.value = generateCalendar(year.value, safeEvents);
}, { immediate: true });

/*   watch(() => props.events, (newEvents) => {
    console.log('Events haben sich geändert:', newEvents);
      calendarData.value = generateCalendar(year.value, newEvents);
    }, {
      immediate: true
    });*/
    // const currentMonth = computed(() => calendarData.value[currentMonthIndex.value]);

	//const months = computed(() => generateCalendar(year.value));
	
/*function getEventDate(eventDate) {
    if (eventDate.date) {
        console.log("Datumstyp: date", eventDate.date);
        return new Date(eventDate.date);
    } else if (eventDate.dateTime) {
        console.log("Datumstyp: dateTime", eventDate.dateTime);
        return new Date(eventDate.dateTime);
    } else {
        console.log("Kein gültiges Datum gefunden");
        return null;
    }
}*/
function getEventDate(eventDate) {
    if (!eventDate) {
        console.log("Kein gültiges Datum übergeben");
        return null;
    }

    // Erkennen, ob das Datum im einfachen oder erweiterten ISO-Format vorliegt
    if (eventDate.includes('T')) {
        // ISO 8601 Datumsformat mit Uhrzeit
        return new Date(eventDate);
    } else {
        // Einfaches YYYY-MM-DD Format
        // Addieren Sie die Uhrzeit 'T00:00:00' um Mitternacht zu gewährleisten
        return new Date(`${eventDate}T00:00:00`);
    }
}
  function generateCalendar(year, events) {
  console.log("Generieren des Kalenders für das Jahr:", year);
  console.log("Verarbeitung folgender Events:", events);
  const monthsArray = [];
  for (let month = 0; month < 12; month++) {
    const daysArray = [];
    const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    
    for (let day = 1; day <= lastDayOfMonth; day++) {
      const date = new Date(year, month, day);
      const dayOfWeek = date.toLocaleDateString('de-DE', { weekday: 'short' }).replace('.', '');
      const dayOfMonth = date.toLocaleDateString('de-DE', { day: 'numeric' });
      const dayString = `${dayOfMonth} ${dayOfWeek}`;

      const dayStart = new Date(year, month, day);
      const dayEnd = new Date(year, month, day, 23, 59, 59, 999);   

      const dayEvents = Array.isArray(events) ? events.filter(event => {
        const startDate = getEventDate(event.start);
        const endDate = getEventDate(event.end);
        return startDate <= dayEnd && endDate > dayStart;
      }) : [];

      daysArray.push({
        date: date,
        dayString: dayString,
        isWeekend: [0, 6].includes(date.getDay()),
        events: dayEvents // Direkte Zuweisung des event-Arrays
      });
    }
    
    const firstDay = new Date(year, month, 1).getDay();
    const leadingEmptyDaysCount = firstDay === 0 ? 6 : firstDay - 1;
    const leadingEmptyDays = Array.from({ length: leadingEmptyDaysCount + 1 }, (_, index) => ({
      isEmpty: true,
      isMonthName: index === leadingEmptyDaysCount,
      monthName: new Date(year, month).toLocaleString('de-DE', { month: 'long' })
    }));

    monthsArray.push({
      id: `${year}-${month + 1}`,
      name: new Date(year, month).toLocaleString('de-DE', { month: 'long' }),
      days: daysArray,
      leadingEmptyDays: leadingEmptyDays
    });
  }

  console.log("Erstellter Kalender für das Jahr", year, ":", monthsArray);
  return monthsArray;
}



 // Berechnete Eigenschaft für die führenden leeren Tage des aktuellen Monats
  /*  const leadingEmptyDays = computed(() => {
      const firstDay = new Date(year.value, currentMonthIndex.value, 1).getDay();
      // Sonntag wird als 0 von getDay() zurückgegeben, daher fügen wir 6 hinzu, wenn firstDay 0 ist,
      // sonst subtrahieren wir 1, um die Anzahl der leeren Zellen vor dem ersten Tag zu berechnen
      const emptyDaysCount = firstDay === 0 ? 6 : firstDay - 1;
      return Array.from({ length: emptyDaysCount }, () => '');
    });*/

 function changeYear(direction) {
      year.value += direction;
      emit('yearChanged', year.value);
    }

   return { 
      year,
  calendarData,
  changeYear, 
  hasMultiDayOrAllDayEvent,
      getDayBorderColor,
      getDayBorderStyle,
  popupVisible,
  generatePDF,
  calendarElement,
  truncateEventTitle,
  formattedEvents,
  processedCalendarData,popupRef, showPopup, closePopup, additionalEvents
      //currentMonth, 
      //leadingEmptyDays 
    };
	
	}
	};
</script>
<style>
.calendar-year {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* 12 Monate des Jahres */
  /*gap: 10px;*/
  margin: 20px;
}

.calendar-month {
  display: flex;
  flex-direction: column;
}

.month-name {
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
}

.days-grid {
  display: grid;
  grid-template-rows: auto; /* Zeilen für die Tage */
  /*gap: 5px;*/
}
/*
.day {
  display: flex;
  justify-content: flex-start; 
  
  height: 50px; 
  border: 1px solid #ddd;
  padding-left: 5px; 
}*/

.day.empty-cell {
  background-color: transparent;
    border: 1px solid transparent; 
}

.day.month-name-cell {
  font-weight: bold; /* Stil für den Monatsnamen */
  justify-content: center; /* Zentrieren des Inhalts in der Flexbox */
   border: none;
   font-size:1.5em;
}

.day.month-name-cell span {
  text-align: center; /* Zentrieren des Textes innerhalb des Span-Elements */
}

.weekend {
  background-color: #8A8A8A; /* Hintergrundfarbe für Wochenenden */
}

.today {
  background-color: #e3e3e3; /* Hintergrundfarbe für den heutigen Tag */
}
.calendar-controls {
  text-align: center;
  margin-bottom: 20px;
}

.calendar-controls button {
  margin: 0 10px;
  padding: 5px 10px;
}
.calendar-container {
  position: relative;
}

.year-display {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 48px; /* Große Schriftgröße */
  margin: 20px; /* Abstand hinzufügen */
}

.event-list li {
    display: flex;
    justify-content: space-between;
}
.popup {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  /* Rest Ihrer Stile */
   position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-width: 300px;
  border-radius: 8px;
}
.popup.popup-visible {
  opacity: 1;
  visibility: visible;
}
.popup button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.popup button:hover {
  background-color: #0056b3;
}
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Stellen Sie sicher, dass das Overlay unter dem Popup liegt */
}

.overlay.overlay-visible {
  display: block;
}
.event-item {
  margin-left: 20px; /* oder ein anderer gewünschter Abstand */
}
.day-with-event {
  position: relative; /* Wichtig für die Positionierung des farbigen Streifens */
}

.day-with-event::after {
   content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 10px; /* Breite des Balkens */
  background-color: inherit;
}
.event-all-day {
  background-color: #f0f0f0; /* Helle Hintergrundfarbe für ganztägige Events */
  border-left: 5px solid; /* Dickerer linker Rand, um das Event hervorzuheben */
 /* padding: 2px 5px;
  margin-bottom: 2px;*/
}


.events {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  margin-top:5px;
  
}

.event-list {
  display: flex;
  flex-direction: column; /* Ändern zu 'column' für vertikale Anordnung */
  flex-wrap: nowrap; /* 'wrap' oder 'nowrap', je nach Bedarf */
  
  list-style-type: none; /* Entfernt die Aufzählungszeichen */
  padding: 0; /* Setzt die Einrückung auf 0, um Linksbindung zu erzielen */
  margin-top: 0;
  margin-bottom:0px;
  padding-bottom:0px;
}

.more-events-indicator {
  cursor: pointer; /* Verändert den Cursor für bessere Benutzerführung */
  /* Weitere Stile für den Indikator, wie z.B. Margin, Padding usw. */
}
.day {
  display: flex;
  flex-direction: row;
  align-items: center; /* Zentriert die Inhalte vertikal */
  min-height: 50px;
  border: 1px solid #ddd;
  padding: 5px;
}

.day-date {
  /* Stil für das Datum */
  margin-right: 10px; /* Abstand zwischen Datum und Event-Liste */
  font-size: 1.5em; /* Größere Schrift für das Datum */
  font-weight:bold;
}

</style>
